<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="memberBrtCe">
          <router-link
            :to="{
              name: 'settingTeams',
            }"
          >
            Teams
          </router-link>
          <span>/</span>
          <span> {{teamName}} </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12" class="pt-0">
        <div class="conOfHeader">
          <div class="headerOfPage">Members</div>
        </div>
      </v-col>
      <v-col md="6" cols="12" class="pt-0">
        <div class="conOfHeader text-right">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="dialogProject = true">
              <v-icon>mdi-plus</v-icon>
              Add New
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="listData.team_users && listData.team_users.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData.team_users"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-title  class="statusTxt"> Delete </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
            </v-data-table>
          </div>
        </section>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-dialog scrollable v-model="dialogProject" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Add New Member</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Name</label>
                <v-autocomplete
                  outlined
                  v-model="formItem.user_id"
                  item-text="name"
                  item-value="id"
                  :items="listTeamLeaders"
                  placeholder="Name"
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                  append-icon="mdi-chevron-down"
                  required
                  :error-messages="user_idErrors"
                  @input="$v.formItem.user_id.$touch()"
                  @blur="$v.formItem.user_id.$touch()"
                  :disabled="isLoadingUsersList"
                  :loading="isLoadingUsersList"
                  @change="getUserData(formItem.user_id)"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                    <span class="leaderSpan">
                      {{ data.item.team_leader ? "Leader" : "" }}
                    </span>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                    <span class="leaderSpan">
                      {{ data.item.team_leader ? "Leader" : "" }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Email</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Email"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.email"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Phone</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Phone"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.phone"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card class="conConfirmPopup">
        <v-card-title>
          <span class="text-h5 modalTitle">Are you sure</span>
        </v-card-title>
        <v-card-text class="textConfirm"
          >Are you sure you want to delete this item?</v-card-text
        >
        <v-card-actions class="pb-7">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDelete">Cancel</v-btn>
          <v-btn
            class="btnPrimaryOrg"
            tile
            @click="deleteItemConfirm"
            :disabled="isLoadingDelete"
            :loading="isLoadingDelete"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EmptyState from "@/modules/shared/components/emptystate";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        user_id: { required },
      },
    };
    return validations;
  },
  data: () => ({
    dialogProject: false,
    dialogDelete: false,
    isLoadingSave: false,
    isLoadingUsersList: false,
    isLoadingDelete: false,
    isLoading: false,
    editedIndex: -1,
    formItem: {},
    defaultItem: {},
    listTeamLeaders: [],
    teamName: "",
    headers: [
      {
        text: "NAME",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "EMAIL", value: "email" },
      { text: "PHONE", value: "phone" },
      { text: "UPDATED DATE", value: "created_at" },
      { text: "", value: "actions", sortable: false, align: "right" },
    ],
    listData: [],
  }),
  methods: {
    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.isLoadingDelete = true;

      let userData = { user_id: this.editedIndex.toString() };
      apiServices
        .post(`teams/${this.$route.params.id}/unassign`, userData)
        .then((res) => {
          if (res) {
            this.getListData(this.$route.params.id);
            this.isLoadingDelete = false;
            this.closeDelete();
          } else {
            this.isLoadingDelete = false;
            this.closeDelete();
          }
        });
    },
    getUserData(id) {
      let filteredLead = this.listTeamLeaders.filter((el) => {
        return el.id == id;
      });
      this.formItem.email = filteredLead[0].email;
      this.formItem.phone = filteredLead[0].phone;
    },
    saveItem() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;
      let userData = { user_id: this.formItem.user_id.toString() };
      apiServices
        .post(`teams/${this.$route.params.id}/assign`, userData)
        .then((res) => {
          if (res) {
            this.getListData(this.$route.params.id);
            this.isLoadingSave = false;
            this.closeProjectModal();
          } else {
            this.isLoadingSave = false;
          }
        });
    },
    closeProjectModal() {
      this.editedIndex = -1;
      this.dialogProject = false;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$v.$reset();
          this.formItem = Object.assign({}, this.defaultItem);
        });
      }, 100);
    },
    closeDelete() {
      this.dialogDelete = false;
      this.editedIndex = -1;

      setTimeout(() => {
        this.$nextTick(() => {
          this.$v.$reset();
          this.formItem = Object.assign({}, this.defaultItem);
        });
      }, 100);
    },
    getListData(id) {
      this.listData = [];
      this.isLoading = true;
      apiServices.get(`teams/${id}`).then((res) => {
        if (res) {
          this.listData = res.data;
          this.teamName = res.data.name;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    getUsersList() {
      this.listTeamLeaders = [];
      this.isLoadingUsersList = true;
      apiServices.get(`team/users`).then((res) => {
        if (res) {
          this.listTeamLeaders  = res.data.filter(el => {
              return el.team_leader == false;
          })
          // this.listTeamLeaders = res.data;
          this.isLoadingUsersList = false;
        } else this.isLoadingUsersList = false;
      });
    },
  },
  components: { EmptyState },
  computed: {
    user_idErrors() {
      const errors = [];
      if (!this.$v.formItem.user_id.$dirty) return errors;
      !this.$v.formItem.user_id.required &&
        errors.push("Team Leader Is Required.");
      return errors;
    },
  },
  created() {
    this.getListData(this.$route.params.id);
    this.getUsersList();
  },
  watch: {
    dialogProject(val) {
      val || this.closeProjectModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_teams-members.scss";
</style>
